import React from 'react'
import './MessageBox.scss'

const MessageBox = ({ success, message }) => {
    return (

        <div className={`message-box animate__animated animate__fadeInRight ${success ? 'message-box__success' : 'message-box__error'}`}>
            {message}
        </div>


    )
}

export default MessageBox