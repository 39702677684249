import React from 'react'
import InfoSetDouble from '../../components/InfoSetDouble/InfoSetDouble'
import presents from '../../images/presents.png'
import sleep from "../../images/sleep.png";
import transport from "../../images/transport.png";


const Nocleg = () => (
    <>Zameldowanie od g. 15:00 <br /> Wymeldowanie do g. 11:00 <br /> Śniadanie od 8:00 do 11:00  </>
)

const InfoSection = () => {
    return (
        <div className='info-section' id="info">
            <InfoSetDouble title="NOCLEG" image={sleep} inverse content={<Nocleg />}
                rightContent={
                    <>
                        <span className="church__content--right">
                            G
                        </span>oście, którzy nocują w hotelu mogą wyruszyć na salę zaraz po złożeniu życzeń i zameldować się w hotelu.<br /><br />
                        Zachęcamy do przedłużenia noclegu na poprawiny,<br />
                        mamy dla Was specjalną zniżkę. <br />
                        <br />
                        Rezerwacji na drugą noc można dokonać zaznaczając okienko w formularzu
                        RSVP na dole strony.
                    </>
                }
            />
            <InfoSetDouble id="transport" image={transport} title="TRANSPORT" content={
                <>
                    Dom -{'>'} Kościół - Na życzenie Gości.<br />
                    Kościół -{'>'} Wesele - Na życzenie Gości.<br />
                    Wesele -{'>'} Dom - Zapewniony przez Młodych. <br />
                </>
            }
                rightContent={
                    <div>
                        <span className="church__content--right">
                            G
                        </span>ościom, którzy nie nocują w Hotelu zapewniamy transport z wesela do domu.<br /><br />
                        Jeżeli życzycie sobie dodatkowy transport z domu do kościoła, lub z kościoła na salę -<br /> dajcie nam znać w formularzu RSVP. Wyślemy kierowcę pod wskazany adres.
                    </div>
                }
            />
            <InfoSetDouble image={presents} inverse title="ŻYCZENIA" content={<div>Miłe słowa i uściski chętnie wymienimy <br /> na sali </div>}
                rightContent={
                    <div>
                        <span className="church__content--right">
                            D
                        </span>ziękujemy Wam za wszystkie toasty, życzenia i miłe słowa jakie spłyną do nas w tym dniu. Przyjmować je będziemy na sali po obiedzie.<br /><br /> Jeżeli macie ochotę obdarować nas czymś dodatkowym, każdy grosz przyda się w nowym domu i każda butelka w domowym barku.
                    </div>
                }
            />
        </div>
    )
}

export default InfoSection