import React from 'react'
import photo from '../../images/photo.jpg'
import { Parallax } from 'react-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import './Photo.scss'
const Photo = () => {
    return (
        <div className='photo'>
            <Parallax bgImage={photo} bgImageAlt="ada i marek" strength={200}>

                <div className='photo__content'>
                    <AnimationOnScroll animateIn="animate__lightSpeedInLeft" duration={1} animateOnce >

                        <div className='photo__content__text'>
                            "Name one way I'm not perfect in."
                            <br />
                            - Homer J. Simpson
                        </div>

                    </AnimationOnScroll>
                </div>

            </Parallax></div>
    )
}

export default Photo