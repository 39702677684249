import React from 'react'
import './TopMenu.scss'
import { Link, animateScroll as scroll } from "react-scroll";
import { Grid } from '@mui/material';
const TopMenu = () => {
  return (
    <Grid containter className='top-menu'>
      <div className='top-menu__button'>
      <Link
        activeClass="active"
        to="church"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >Ślub</Link>
      </div>
      <div className='top-menu__button' ><Link
        activeClass="active"
        to="party"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
      >Wesele</Link>
      </div>
      <div className='top-menu__button'>
        <Link
          activeClass="active"
          to="info"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >Nocleg</Link></div>
      <div className='top-menu__button'>
        <Link
          activeClass="active"
          to="transport"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >Transport</Link></div>

      <div className='top-menu__button'>
        <Link
          activeClass="active"
          to="foodmenu"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >MENU
        </Link>
      </div>
      <div className='top-menu__button'>
        <Link
          activeClass="active"
          to="wege"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >WEGE
        </Link>
      </div>
     
      </Grid>
  )
}

export default TopMenu