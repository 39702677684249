import React, { useEffect, useState } from "react";

import Title from "./sections/Title/Title";
import TopMenu from "./components/TopMenu/TopMenu";
import "./styles.scss"
import Church from "./sections/Church/Church";
import Party from "./sections/Party/Party";
import About from "./sections/About/About";
import Footer from "./sections/Footer/Footer";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import InfoSection from "./sections/InfoSection/InfoSection";
import "animate.css/animate.min.css";
import { Menu, useScrollTrigger } from "@mui/material";
import { set } from "react-hook-form";
import RSVPForm from "./sections/RSVPForm/RSVPForm";
import Photo from "./sections/Photo/Photo";
import DimLoader from "./components/DimLoader/DimLoader";
import { usePostEmailMutation } from "./store/api";
import MessageBox from "./components/MessageBox/MessageBox";
import FoodMenu from "./sections/FoodMenu/FoodMenu";
const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Bludhaven',
      textTransform: 'uppercase',
      fontSize: 16,
    },
  },
});

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [sendRequest, { data, isLoading }] = usePostEmailMutation();
  const handleResize = () => {
    if (window.innerWidth < 576) {
      setIsTablet(false);
      setIsMobile(true);
    } else if (window.innerWidth < 1200 && window.innerWidth > 576) {
      setIsTablet(true);
      setIsMobile(false);
    } else {
      setIsMobile(false);
      setIsTablet(false);
    }
  }
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [isTablet, setIsTablet] = useState(false);
  useEffect(() => {

  }, []);
  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <TopMenu />
        <Title />
        <About />
        <Church {...{ isMobile, isTablet }} />
        <Party {...{ isMobile, isTablet }} />
        <InfoSection />
        <Photo />
        {/* <RSVPForm {...{ sendRequest, data }} /> */}
        <DimLoader {...{ isLoading }} />
        <FoodMenu />
        {data && (
          <MessageBox success={data.success} message={data.message} />
        )}

        <Footer />
      </div>
    </ThemeProvider>

  );
};
export default App;
