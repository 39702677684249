import React from 'react'
import InfoSetDouble from '../../components/InfoSetDouble/InfoSetDouble'
import party from '../../images/party.png'
import grill from '../../images/grill.png'

import shuma2 from '../../images/shuma3.jpg'
import './Party.scss'
import { Parallax } from 'react-parallax'
import { AnimationOnScroll } from 'react-animation-on-scroll'
import { Button } from '@mui/material'
const Wesele = () => (
  <>Piątek, 06 Września 2024 g. 16:30<br />
    Hotel Shuma<br />
    ul. Żeglarska 20, Dąbrowa Górnicza</>
)
const Poprawiny = () => (
  <>Sobota, 07 Września 2024 g. 14:00<br />
    Hotel Shuma<br />
    ul. Żeglarska 20, Dąbrowa Górnicza</>
)


const Party = ({ isMobile, isTablet }) => {
  return (
    <div className='party' id="party">

      <InfoSetDouble title="WESELE" content={<Wesele />} image={party} animateIn="animate__pulse" inverse rightContent={<div >
        <span className="church__content--right">
          P
        </span>o ceremonii, zapraszamy Was do hotelu Shuma z nadzieją,<br /> że będziemy świętować i głośno wznosić toasty.<br /><br />
        Przyjęcie weselne to nie tylko impreza - to święto Miłości, Przyjaźni i nas Wszystkich.
        Dlatego bądźmy w ten dzień razem i bawmy się do białego rana.<br /><br />
      </div>} />

      <InfoSetDouble title="POPRAWINY" content={<Poprawiny />} image={grill} rightContent={
        <div>
          <span className="church__content--right">
            W
          </span> dzień poprawin od rana możecie korzystać z pięknych okolic hotelu i jeziora.
          Zabierzcie stroje kąpielowe - do Waszej dyspozycji będzie plaża, sup i rowerki wodne.<br /> <br />
          O 14 rozpoczynamy grillowanie.
          Garnitury zostawcie na wieszakach, ubierzcie się wygodnie i nie planujcie powrotu przed północą - świętujemy hucznie i długo.
      </div>} />

      <Parallax bgImage={shuma2} className="party__parallax" bgImageAlt="shuma" strength={300}>
        <div className="party__directions">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={2} animateOnce >

            {!isMobile ?
              <div className="party__directions__map">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10182.599363922402!2d19.2356757!3d50.3544361!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716d90a4bea6663%3A0x71d483ad6d9f5ab4!2sHotel%20Shuma%20-%20D%C4%85browa%20G%C3%B3rnicza%20%7C%20Restauracja%20%26%20Konferencje!5e0!3m2!1spl!2spl!4v1698146960845!5m2!1spl!2spl" width={isTablet ? "550" : "715"} height="475" style={{ "border": "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
              </div> : <div className="party__directions__mobile">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10182.599363922402!2d19.2356757!3d50.3544361!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716d90a4bea6663%3A0x71d483ad6d9f5ab4!2sHotel%20Shuma%20-%20D%C4%85browa%20G%C3%B3rnicza%20%7C%20Restauracja%20%26%20Konferencje!5e0!3m2!1spl!2spl!4v1698146960845!5m2!1spl!2spl" width="300" height="300" style={{ "border": "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
                <Button href="https://maps.app.goo.gl/342ADqJmPDNDU7gKA" variant="contained" className="church__directions__button">Otwórz w google</Button>
              </div>}



          </AnimationOnScroll>
        </div>
      </Parallax>


    </div>
  )
}

export default Party