import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const BASE_URL = "https://wesele-mail-81ab4f8548eb.herokuapp.com/";
//const BASE_URL = "http://localhost:3000";
export const exampleApi = createApi({
  reducerPath: "exampleApi",

  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),

  endpoints: (builder) => ({
    getExampleRequest: builder.query({
      query: () => `todos/1`
    }),

    postEmail: builder.mutation({
      query: (body) => ({
        url: "/send-email",
        method: "POST",
        body
      })
    })
  })
});

export const {
  useGetExampleRequestQuery,
  usePostEmailMutation
} = exampleApi;
