import React from 'react'
import about from '../../images/my.jpg'
import { Parallax } from 'react-parallax';
import './About.scss'
import { AnimationOnScroll } from 'react-animation-on-scroll';
const About = () => {
  return (
    <div className='about'>
      <Parallax bgImage={about} bgImageAlt="ada i marek" strength={200}>

          <div className='about__content'>
            <AnimationOnScroll animateIn="animate__lightSpeedInRight" duration={1} animateOnce >
            <a href='https://www.youtube.com/watch?v=ZUPjliWdsJ8'>
              <div className='about__content__text'>
                "Oh... why do my actions have consequences?"
                <br />
                - Homer J. Simpson
              </div>
            </a>
              </AnimationOnScroll>
          </div>
      
      </Parallax></div>
  )
}

export default About