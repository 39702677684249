import React from 'react'
import heart from "../../images/heart.png"
import background from "../../images/title-back.png"
import "./FoodMenu.scss"
import { AnimationOnScroll } from 'react-animation-on-scroll'
const FoodMenu = () => {
  return (
    <>
      <div className="food" id="foodmenu">

        <div className="food__title">
          <AnimationOnScroll animateIn="animate__fadeInDown" duration={1} animateOnce ><div>Menu</div>
          </AnimationOnScroll>
        </div>





        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Obiad
          </AnimationOnScroll>
        </div>





        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            domowy rosół z makaronem
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jajka / gluten
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            rolada śląska
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gorczyca
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            kotlet de volaille
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / jajka / mleko
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            grillowana pierś z kaczki (sous vide) z musem jabłkowym
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jabłko / mleko
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Sos pieczeniowy ciemny
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gorczyca / gluten
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Sos śliwkowy na czerwonym winie
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko / siarczany
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Kluski śląskie
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Ćwiartki z ziemniaków pieczone z rozmarynem
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Mizeria ze śmietaną i koperkiem
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Buraczki
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Modro Kapusta
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / mleko
          </AnimationOnScroll>
        </div>











        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Deser
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Brownie z wiśniami duszonymi w czerwonym winie
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jajka / mleko / gluten
          </AnimationOnScroll>
        </div>




        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Zimna Płyta
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Pasztet
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / jajka
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Sery
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Jajka faszerowane
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            ryby / jajka / mleko / gorczyca
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Tortilla z łososiem i serkiem
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / ryby / mleko
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Koreczki caprese
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Babeczki wytrawne
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / mleko
          </AnimationOnScroll>
        </div>




        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Śledzie w śmietanie
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko / ryby
          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Ryba w occie
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / jajka
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Sałatka gyros
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jajka / gorczyca
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Sałatka jarzynowa
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jajka / gorczyca
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Masło
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Pieczywo
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            gluten / sezam
          </AnimationOnScroll>
        </div>






        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            KOLACJA I
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            21:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Boeuf Strogonow
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko / gluten
          </AnimationOnScroll>
        </div>




        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Deser II
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            22:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Tort Orzechowe Latte
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko / gluten / orzechy / jajka
          </AnimationOnScroll>
        </div>







        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            KOLACJA II
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            24:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content" >
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            <a className="food__meal--pig" href="https://www.youtube.com/watch?v=wDHu3b9QQBk" >
              <div>
                Płonący udziec wieprzowy Z ZIEMNIAKAMI i kapustą zasmażaną
              </div>
            </a>
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mąka
          </AnimationOnScroll>
        </div>





        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            KOLACJA III
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            03:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content" >
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            <a className="food__meal--pig" href="https://www.youtube.com/watch?v=wDHu3b9QQBk" >
              <div>
                Barszcz z krokietem
              </div>
            </a>
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jakja / gluten
          </AnimationOnScroll>
        </div>



        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animateOnce >

        </AnimationOnScroll>


      </div>




















      <div className="food" id="wege">

        <div className="food__title">
          <AnimationOnScroll animateIn="animate__fadeInDown" duration={1} animateOnce ><div>Wege</div>
          </AnimationOnScroll>
        </div>





        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Obiad
          </AnimationOnScroll>
        </div>





        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            francuska zupa cebulowa
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
          </AnimationOnScroll>
        </div>
        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            spaghetti z cukinią z suszonymi pomidorami
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
          </AnimationOnScroll>
        </div>

        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Deser
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Brownie z wiśniami duszonymi w czerwonym winie
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jajka / mleko / gluten
          </AnimationOnScroll>
        </div>




        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Zimna Płyta
          </AnimationOnScroll>
        </div>


        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Humus Sycylijski
          </AnimationOnScroll>
        </div>

        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >

          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Antipasti (grilowane warzywa i tzatziki)
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >

          </AnimationOnScroll>
        </div>



        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Marynaty, masełko ziołowe, pieczywo jasne i ciemne
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >

          </AnimationOnScroll>
        </div>

        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            KOLACJA I
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            21:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            curry z ciecierzycą batatami i szpinakiem
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >

          </AnimationOnScroll>
        </div>




        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            Deser II
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            22:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            Tort Orzechowe Latte
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            mleko / gluten / orzechy / jajka
          </AnimationOnScroll>
        </div>







        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            KOLACJA II
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            24:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content" >
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >

            <div>
              papryka nadziewana
            </div>

          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
          </AnimationOnScroll>
        </div>





        <div className="food__meal--title">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            KOLACJA III
          </AnimationOnScroll>
        </div>
        <div className="food__meal--time">
          <AnimationOnScroll animateIn="animate__fadeInRight" duration={1} animateOnce >
            03:00
          </AnimationOnScroll>
        </div>

        <div className="food__meal--content" >
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            <a className="food__meal--pig" href="https://www.youtube.com/watch?v=wDHu3b9QQBk" >
              <div>
                krokiety ruskie z sosem serowo-pieprzowym
              </div>
            </a>
          </AnimationOnScroll>
        </div>
        <div className="food__meal--allergens">
          <AnimationOnScroll animateIn="animate__fadeInUp" duration={2} animateOnce >
            jakja / gluten
          </AnimationOnScroll>
        </div>



        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animateOnce >

        </AnimationOnScroll>


      </div>
    </>
  )
}

export default FoodMenu;