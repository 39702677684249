import React from 'react'
import './InfoSet.scss'
import { Grid } from '@mui/material'
const InfoSet = ({ inverse, image, title, content }) => {
  return (
    <Grid container className={`info ${inverse && "info--inverse"}`} spacing={1}>
      {!inverse && <Grid item className="info__left-img" xs={12} lg={3}>
        <img className='info__img' src={image} />
      </Grid>}
      <Grid item xs={12} lg={9}>
        <div className={`info__text ${inverse && "info__text--inverse"}`}>
          <div className="info__text__title"> {title} </div>
          <div className="info__text__content"> {content} </div>
        </div>
      </Grid>
      <Grid item className="info__right-img" xs={12} lg={3}>
        {inverse && <img className='info__img' src={image} />}
      </Grid>
    </Grid>
  )
}

export default InfoSet