import React from 'react'
import './InfoSetDouble.scss'
import { Grid } from '@mui/material'
import InfoSet from '../InfoSet/InfoSet'
import { AnimationOnScroll } from 'react-animation-on-scroll'

const InfoSetDouble = ({ inverse, image, title, content, rightContent, animateIn, id }) => {
  return (
    <Grid container id={id} className={`info-double ${inverse ? "info-double--inverse" : ""}`} >
      <Grid item xs={12} lg={6} className="info-double__left">
        <AnimationOnScroll animateIn={inverse ? "animate__backInLeft" : "animate__backInRight"} duration={1} animateOnce >
          <InfoSet inverse={inverse} image={image} title={title} content={content} animateIn={animateIn} />
        </AnimationOnScroll>
      </Grid>
      <Grid item xs={12} lg={6} className="info-double__right">
        <AnimationOnScroll animateIn="animate__fadeIn" duration={1} animateOnce >
          {rightContent}
        </AnimationOnScroll>
      </Grid>
    </Grid>
  )
}

export default InfoSetDouble