import React from 'react'
import churchIco from "../../images/church-ico.png"
import church2 from "../../images/church2.jpg"
import "./Church.scss"
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Parallax } from 'react-parallax';
import InfoSetDouble from '../../components/InfoSetDouble/InfoSetDouble';
import { TextField, Button, Grid } from '@mui/material';
import { Link } from 'react-scroll';

const Church = ({ isMobile, isTablet }) => {
  return (
    <div className="church" id="church">
      {/* <h2 className="church__title">Ślub</h2> */}

      <InfoSetDouble image={churchIco}
        title="ŚLUB"
        inverse
        content={
          <div>Piątek, 06 Września 2024, g. 14:00,<br />
            Parafia Matki Bożej Częstochowskiej<br />
            Górnośląska 5, Mysłowice</div>}
        rightContent={

          <div className="">

            <span className="church__content--right">
              K
            </span>ochani, jesteśmy bardzo szczęśliwi mogąc z Wami świętować tę najpiękniejszą z uroczystości.
            <br /> <br />Parafia w Mysłowickich Kosztowach to rodzinna parafia Marka i właśnie w niej zdecydowaliśmy się powiedzieć sobie sakramentalne “tak”.
            <br /> <br />Przybywajcie licznie, siadajcie blisko i bądźcie z nami w ten wyjątowy piątek.
          </div>
        } />

      <Parallax className="church__parallax" bgImage={church2} bgImageAlt="church" strength={300}>
        <div className="church__directions">
          <AnimationOnScroll animateIn="animate__fadeInLeft" duration={2} animateOnce >

            {!isMobile ?
              <div className="church__directions__map">

                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2554.5527345407368!2d19.15659527755986!3d50.18819840621058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716c41249b8840f%3A0xf178fdcb04c6bf6c!2sParafia%20MB%20Cz%C4%99stochowskiej%20w%20Kosztowach!5e0!3m2!1spl!2spl!4v1698139775813!5m2!1spl!2spl" width={isTablet ? "550" : "715"} height="475" style={{ "border": "0" }} allowFullScreen="" disableDefaultUI="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
              : <div className='church__directions__mobile'>  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2554.5527345407368!2d19.15659527755986!3d50.18819840621058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716c41249b8840f%3A0xf178fdcb04c6bf6c!2sParafia%20MB%20Cz%C4%99stochowskiej%20w%20Kosztowach!5e0!3m2!1spl!2spl!4v1698139775813!5m2!1spl!2spl" width="300" height="300" style={{ "border": "0" }} allowFullScreen="" disableDefaultUI="true" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <Button
                  href="https://www.google.com/maps/place/Parafia+MB+Częstochowskiej+w+Kosztowach,+Górnośląska+5,+41-409+Mysłowice/@50.188195,19.15917,17z/data=!4m6!3m5!1s0x4716c41249b8840f:0xf178fdcb04c6bf6c!8m2!3d50.188195!4d19.1591702!16s%2Fg%2F121_twr7?hl=pl&gl=PL"
                  variant="contained"
                  className="church__directions__button">Otwórz w google</Button></div>}

          </AnimationOnScroll>
        </div>
      </Parallax>

    </div>

  )
}

export default Church