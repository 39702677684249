import React from 'react'
import './Footer.scss'
import obr from '../../images/obr.png'
import { AnimationOnScroll } from 'react-animation-on-scroll'
const Footer = () => {
  return (
    <div className='footer'>
        aŚŚŚENAEEEEBIEMY
      <AnimationOnScroll animateIn="animate__lightSpeedInRight" duration={1} animateOnce >
      <img className='footer__img' src={obr} />
      </AnimationOnScroll>
    </div>
  )
}

export default Footer