import React from 'react'
import heart from "../../images/heart.png"
import background from "../../images/title-back.png"
import "./Title.scss"
import { AnimationOnScroll } from 'react-animation-on-scroll'
const Title = () => {
  return (
    <div className="title" style={{ backgroundImage: `url(${background})` }}>

      <h1 className="title__content">
        <AnimationOnScroll animateIn="animate__fadeInDown" duration={1} animateOnce ><div>
          ADRIANNA
        </div>
        </AnimationOnScroll>      
        <AnimationOnScroll animateIn="animate__heartBeat" duration={5} animateOnce >
         <img className="title__image" src={heart} />   
        </AnimationOnScroll>     
        <AnimationOnScroll animateIn="animate__fadeInUp" duration={1} animateOnce >
          <div>MAREK</div> 
        </AnimationOnScroll></h1>


    </div>
  )
}

export default Title