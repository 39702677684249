import React from 'react'
import './DimLoader.scss'
import { CircularProgress } from '@mui/material'

const DimLoader = ({ isLoading }) => {
    return isLoading && (
        <div className='dim-loader'>
            <CircularProgress />
        </div>
    )
}

export default DimLoader